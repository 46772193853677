<template>
  <a href="https://yco.hu/en/" target="_blank" rel="noreferrer noopener" class="site-footer__credits__link">
    <svg width="30" height="30" viewBox="0 0 332 185" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M199.652 8.70649C184.774 15.6931 172.065 26.535 162.812 39.9452C177.458 40.5909 190.541 47.2498 199.651 57.5018C208.767 47.2443 221.848 40.5903 236.491 39.9452C227.237 26.5325 214.528 15.692 199.652 8.70649Z"
        fill="#010101"
      />
      <path
        d="M194.514 64.326C186.427 54.494 174.177 48.2347 160.466 48.2347C159.526 48.2347 158.593 48.2641 157.668 48.3221C150.57 61.3959 146.538 76.3767 146.538 92.2991C146.538 108.221 150.57 123.202 157.668 136.276C158.593 136.334 159.526 136.363 160.466 136.363C174.177 136.363 186.427 130.104 194.514 120.272C189.395 112.178 186.432 102.585 186.432 92.2991C186.432 82.0136 189.395 72.4202 194.514 64.326Z"
        fill="#010101"
      />
      <path
        d="M246.762 144.109C271.945 140.289 291.243 118.548 291.243 92.2991C291.243 66.0503 271.945 44.3088 246.762 40.4889C237.797 25.5872 225.078 13.195 209.911 4.62321C219.01 1.62311 228.734 -0.000244141 238.837 -0.000244141C289.813 -0.000244141 331.137 41.3236 331.137 92.2991C331.137 143.275 289.813 184.598 238.837 184.598C228.734 184.598 219.01 182.975 209.911 179.975C225.078 171.403 237.797 159.011 246.762 144.109Z"
        fill="#010101"
      />
      <path
        d="M199.651 127.096C208.767 137.354 221.848 144.008 236.491 144.653C227.237 158.066 214.528 168.906 199.652 175.892C184.774 168.905 172.065 158.063 162.812 144.653C177.458 144.007 190.541 137.348 199.651 127.096Z"
        fill="#010101"
      />
      <path
        d="M0.654596 0.0598145H46.72L76.9065 52.3444L107.058 0.120109H153.124L46.6152 184.598H0.549805L53.8738 92.2382L0.654596 0.0598145Z"
        fill="#010101"
      />
      <path
        d="M81.7227 140.474C97.9498 166.941 127.147 184.598 160.467 184.598C191.997 184.598 219.839 168.79 236.493 144.652C221.849 144.004 208.767 137.343 199.658 127.09C190.063 137.89 176.061 144.704 160.467 144.704C132.348 144.704 109.401 122.558 108.118 94.7555L81.7227 140.474Z"
        fill="#010101"
      />
      <path
        d="M136.43 45.719C143.629 41.996 151.803 39.8933 160.467 39.8933C176.061 39.8933 190.063 46.7073 199.658 57.5072C208.767 47.254 221.849 40.5934 236.493 39.9453C220.253 16.4066 193.373 0.789581 162.809 0.0285645L136.43 45.719Z"
        fill="#010101"
      />
    </svg>
  </a>
</template>

<style lang="scss" scoped></style>
